import React, { useState , useEffect , useRef , useContext } from 'react'
import { CSSTransition } from 'react-transition-group';
import queryString from 'query-string'
import axios from 'axios'
import { getStatusPageAlertChannels } from '../../../utils/helper'
import { ReactComponent as PreviousIcon } from './../../../assets/icons/previous-circle.svg'
import LoadingSpinner from '../../Layout/LoadingSpinner/LoadingSpinner'
import { getPhonePrefixes } from '../../../utils/helper'; 
import {Link} from 'react-router-dom'
import fs  from 'fs';
import './SubscribersDropMenu.css'

// Invoke the global state
import AppContext from './../../../context/app-context'


let useClickOutside = (handler) => {
	let domNode = useRef()

	useEffect(() => {
		
		let maybeHandler = (event) => {
			if(!domNode.current.contains(event.target)){
				handler();
			}
		};

		document.addEventListener('mousedown' , maybeHandler);

		return () => {
			document.removeEventListener('mousedown' , maybeHandler)
		}
	})

	return domNode;


}
/**
 * 
 * @param {*} props 
 * @returns 
 */
function SubscribersDropDown(props) {

	const [isOpenMenu, setOpenMenu] = useState(false);
	const [response, setResponse ] = useState('');
	const [responseStatus, setResponseStatus ] = useState('warning');
	const context = useContext(AppContext)
	const globalStatusPageData = context.getStatusPageData();

	useEffect(() => {


		// ! GET QUERY PARAMETERS ( AS RETURN FROM SLACK AUTH VIA OUR OWN REST API )
		const url = window.location.href;
		const searchQuery = new URL(url).search;
		const { slack_message_token , discord_message_token , subscription_confirmed , unsubscribe_confirmed } = queryString.parse(searchQuery)
		 
		// Display the result of Slack processing 
		if(typeof slack_message_token!=='undefined' && slack_message_token!==null && slack_message_token!==''){
			

			switch (slack_message_token) {
				case 'default_success':
					publishResponse(`You're now subscribed to get ${typeof globalStatusPageData!=='undefined' && globalStatusPageData !==null && globalStatusPageData.statusPageName} updates in Slack!` , "success");
					break;
				
				case 'slack_auth_error':
					publishResponse("The Slack authorization attempt was unsuccessful. Try again." , "danger");
					break;
				
				case 'duplicate_error':
					publishResponse(`You're already subscribed to get Slack notifications in that channel.` , "danger");
					break;
			
				default:
					publishResponse("The Slack authorization attempt was undangerful. Try again." , "success");
					break;
			}
		}

		// Display the result of Discord processing 
		if(typeof discord_message_token!=='undefined' && discord_message_token!==null && discord_message_token!==''){
			

			switch (discord_message_token) {
				case 'default_success':
					publishResponse(`You're now subscribed to get ${typeof globalStatusPageData!=='undefined' && globalStatusPageData !==null && globalStatusPageData.statusPageName} updates in Discord!` , "success");
					break;
				
				case 'discord_auth_error':
					publishResponse("The Discord authorization attempt was unsuccessful. Try again." , "danger");
					break;
				
				case 'duplicate_error':
					publishResponse(`You're already subscribed to get Discord notifications in that channel.` , "danger");
					break;
			
				default:
					publishResponse("The Discord authorization attempt was undangerful. Try again." , "success");
					break;
			}
		}

		// Display the subscription confirmation processing 
		if(typeof subscription_confirmed !=='undefined' && subscription_confirmed !==null && subscription_confirmed!==''){
			switch (subscription_confirmed) {
				case 'true':
					publishResponse(`Great! Your email is now subscribed to ${typeof globalStatusPageData!=='undefined' && globalStatusPageData !==null && globalStatusPageData.statusPageName}.` , "success");
					break;
				
				default:
					publishResponse("Your email could not confirm correctly, please try again later" , "danger");
					break;
			}
		}
		// Display the subscription confirmation processing 
		if(typeof unsubscribe_confirmed !=='undefined' && unsubscribe_confirmed !==null && unsubscribe_confirmed!==''){
			switch (unsubscribe_confirmed) {
				case 'true':
					publishResponse(`You have unsubscribed correcly from ${typeof globalStatusPageData!=='undefined' && globalStatusPageData !==null && globalStatusPageData.statusPageName}.` , "success");
					break;
				
				default:
					publishResponse("An error occurred during your unsubscribe process, please try again later" , "danger");
					break;
			}
		}




	},[])

	const publishResponse = (message , status ) => {
		setResponse(message);
		setResponseStatus(status)
		setTimeout(() => {
			setResponse('')
		}, 3000);
	}

	const domNode = useClickOutside(() => {
		setOpenMenu(false)
	})

	return(
		<div className="subscribers-dropdown-menu" ref={domNode}>
			<div className="btn btn-primary dropdown-btn" onClick={() => setOpenMenu(!isOpenMenu)}>Subscribe to updates</div>
			{
				isOpenMenu === true &&   
				<DropdownMenu 
					allowedChannelsSubs={props.allowedChannelsSubs} 
					statusPageDomain={props.statusPageDomain}
					statusPageType={props.statusPageType}
					publishResponse={publishResponse}
					></DropdownMenu>
			}
			<div className={`dropdown-response ${responseStatus} ${typeof response !=='undefined' && response !== null && response !=='' ? '' : 'hide'}`}>{response}</div>
		</div>
	
	)
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
function DropdownMenu(props) {
	const [activeMenu, setActiveMenu] = useState('main');
	const [menuHeight, setMenuHeight] = useState(null);
	
	const dropdownRef = useRef(null);
	const context = useContext(AppContext)
	const globalStatusPageData = context.getStatusPageData();
 
  
	useEffect(() => {
	  	setMenuHeight(dropdownRef.current?.firstChild.offsetHeight + 32)
	}, [])
  
	function calcHeight(el) {
		const height = el.offsetHeight + 32;
		 
		setMenuHeight(height);
	}

	function DropdownItem_previous(props){
		return(
			<div className="menu-item previous-item" onClick={ () => props.goToMenu && setActiveMenu(props.goToMenu)} >
				<PreviousIcon />
				<span>Back </span>
			</div> 
		)
	}

	

	return (
		<React.Fragment>
		<div 
			className="dropdown-menu dropdown-menu-right medium-width dropdown-menu-grid-channel" 
			style={{ height: menuHeight }} 
			ref={dropdownRef}>
			<CSSTransition
				in={activeMenu === 'main'}
				timeout={500}
				classNames="menu-primary"
				unmountOnExit
				onEnter={calcHeight}>
				<div className="menu menu-form-wrapper">
					<div className="menu-title">Get notified whenever Statuspage creates, updates or resolves an incident.</div> 
					<ActiveAlertChannels 
						allowedChannelsSubs={props.allowedChannelsSubs}
						setActiveMenu={setActiveMenu}
						
					/>
				</div>
			</CSSTransition>

			<CSSTransition
				in={activeMenu === 'email'}
				timeout={500}
				classNames="menu-secondary"
				unmountOnExit
				onEnter={calcHeight}>
				<div className="menu email-menu-wrapper ">
					<DropdownItem_previous goToMenu="main"  />
					<EmailChannelForm 
						setResponse={props.publishResponse} 
						statusPageDomain={globalStatusPageData.statusPageAccessInfo.domain}
						statusPageType={globalStatusPageData.statusPageAccessInfo.type} />
				</div>
			</CSSTransition>

			<CSSTransition
				in={activeMenu === 'slack'}
				timeout={500}
				classNames="menu-secondary"
				unmountOnExit
				onEnter={calcHeight}>
				<div className="menu slack-menu-wrapper ">
					<DropdownItem_previous goToMenu="main"  />
					<SlackChannelForm 
						setResponse={props.publishResponse} 
						statusPageDomain={globalStatusPageData.statusPageAccessInfo.domain}
						statusPageType={globalStatusPageData.statusPageAccessInfo.type}
						statusPageName={globalStatusPageData.statusPageName}
						/>
				</div>
			</CSSTransition>

			<CSSTransition
				in={activeMenu === 'discord'}
				timeout={500}
				classNames="menu-secondary"
				unmountOnExit
				onEnter={calcHeight}>
				<div className="menu discord-menu-wrapper ">
					<DropdownItem_previous goToMenu="main"  />
					<DiscordChannelForm 
						setResponse={props.publishResponse} 
						statusPageDomain={globalStatusPageData.statusPageAccessInfo.domain}
						statusPageType={globalStatusPageData.statusPageAccessInfo.type}
						statusPageName={globalStatusPageData.statusPageName}
						/>
				</div>
			</CSSTransition>

			<CSSTransition
				in={activeMenu === 'sms'}
				timeout={500}
				classNames="menu-secondary"
				unmountOnExit
				onEnter={calcHeight}>
				<div className="menu sms-menu-wrapper ">
					<DropdownItem_previous goToMenu="main"  />
					<SMSChannelForm 
						setResponse={props.publishResponse} 
						statusPageDomain={globalStatusPageData.statusPageAccessInfo.domain}
						statusPageType={globalStatusPageData.statusPageAccessInfo.type} />
				</div>
			</CSSTransition>

			<CSSTransition
				in={activeMenu === 'webhook'}
				timeout={500}
				classNames="menu-secondary"
				unmountOnExit
				onEnter={calcHeight}>
				<div className="menu webhooks-menu-wrapper ">
					<DropdownItem_previous goToMenu="main"  />
					<WEBHOOKChannelForm 
						setResponse={props.publishResponse} 
						statusPageDomain={globalStatusPageData.statusPageAccessInfo.domain}
						statusPageType={globalStatusPageData.statusPageAccessInfo.type} />
				</div>
			</CSSTransition>

			<CSSTransition
				in={activeMenu === 'rss'}
				timeout={500}
				classNames="menu-secondary"
				unmountOnExit
				onEnter={calcHeight}>
				<div className="menu rss-menu-wrapper">
					<DropdownItem_previous goToMenu="main"  />
					<RSSChannelForm 
						setResponse={props.publishResponse} 
						statusPageDomain={globalStatusPageData.statusPageAccessInfo.domain}
						statusPageType={globalStatusPageData.statusPageAccessInfo.type} />
				</div>
			</CSSTransition>
		</div>
		
		</React.Fragment>
	);
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
function ActiveAlertChannels(props) {

	 
 
	return(
		
			getStatusPageAlertChannels().filter((x) =>
				props.allowedChannelsSubs.includes(x.id)
			)
			.map((item, index) => {
				return (
					<div key={index} className="subs-ddm_channels-item dropdown-item ">
						<div 
							className="subs-ddm_channels-item_content dropdown-item_content" 
							onClick={() =>
									props.setActiveMenu(item.id)
							} >
							{
								typeof item.icon !== "undefined" &&
								item.icon !== null &&
								item.icon !== "" 
								? <span
									className={`dropdown-main-icon ${item.icon} ${item.bigIcon === true ? "big-icon" : ""
										}`} ></span>
								:  ""
							}
							<div className="di-text_content">
								<span
									className={`title ${"description" in item ? "bold" : ""
										}`}
								>
									{item.name}
								</span>
								{"description" in item &&
									item.description !== "undefined" &&
									item.description !== null &&
									item.description !== "" ? (
									<p className="desc">{item.description}</p>
								) : (
									""
								)}
							</div>
						</div>
						
					</div>
				)
			})
		
	)
 
}

/**
 * Email Channel Form
 * @returns 
 */
function EmailChannelForm(props){

	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const [emailRegex, setEmailRegex] = useState(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
	const [emailValid, setEmailValid] = useState(false);
	const [emailStartChange, setEmailStartChange] = useState(false)
	const [emailErrorResponse, setEmailErrorResponse] = useState('')
	 			

	/**
	 * SUBMIT THE EMAIL ALERT CHANNEL FOR SUBSCRIBERS 
	 */
	const submitEmailSubscription = async (event) => {
		
		event.preventDefault();

		setLoading(true)

		if(
			(typeof email ==='undefined' || email ===null || email ==='' ) || 
			emailValid === false 
		){

			props.setResponse("An error occurred while subscribing via email notification" , 'danger')
			setLoading(false)
			return ;
		}

		
		const headers = {
			'Content-Type': 'application/json',
		}

		let customConfig = {
			type : 'EMAIL',
			config :  {
				address : email
			}
		}

		 
		 
		await axios
			.post(`${process.env.REACT_APP_API_URL}public/statuspages/${props.statusPageDomain}/subscribers`, 
			customConfig
			,{
				headers: headers,
				 
			})
			.then(res => res.data ) 
			.then(data => { 

				setEmail('') // re initialize the email value 

				props.setResponse("Your subscription has been created successfully" , "success");
				setLoading(false); // stop loading ... 
				 
				 

					
			})
			.catch(err => {

				if (err.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					// console.log(err.response.data);
					// console.log(err.response.status);
					// console.log(err.response.headers);
					if(err.response.status === 403){
						props.setResponse(err.response.data.message , 'danger')
					}else{
						props.setResponse("An error occurred while subscribing via email notification" , 'danger')
					}

				} else if (err.request) {
					// The request was made but no response was received
					// `err.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					// console.log(err.request);
					props.setResponse("An error occurred while subscribing via email notification" , 'danger')
				} else {
					// Something happened in setting up the request that triggered an err
					// console.log('err', err.message);
					props.setResponse("An error occurred while subscribing via email notification" , 'danger')
				}
				setLoading(false)
				 
			 
			});

	}
	
	/**
	 * 
	 * @param {*} event 
	 */
	const handleChange_emailInput = (event) => {
		 
		const value = event.target.value ;

		let isValid = false;
		let isEmpty = true; 

		if (typeof value !== 'undefined' &&
			value !== "") {
			
			isEmpty = false;
			if (value.match(emailRegex) !== null) isValid = true;
		}

		setEmail(value);
		setEmailStartChange(true);
		setEmailValid(isValid);
		setEmailErrorResponse(isEmpty === true ? "The email field is required." : (isValid === false ?  "The email field does not have the right format." : "" ))
		 
	}
	 
	return(
		<React.Fragment>
			<div className="channel-form-wrapper email-channel-form" >
				<div className="channel-form-title">Get email notifications whenever Atlassian Statuspage creates, updates or resolves an incident.</div>
				<div className="cr-form-wrapper">
					<form onSubmit={(e) => submitEmailSubscription(e)} >
						<div className="field-group">
							<label className="fluid">Email </label>
							<input type="text" className={`textField fluid ${emailStartChange === true && emailErrorResponse !== "" ? 'error' : ''}`} value={email} placeholder={`Email Address`} onChange={handleChange_emailInput} />
							<span className={`fieldText_alert--error ${emailStartChange === true && emailErrorResponse !== "" ? '' : 'hide'}`}>{emailErrorResponse}</span>
												
						</div>
						<div className="submit-fields-group">
							<button type="submit" className="btn btn-primary fluid margin-left-10" disabled={!emailValid}   >
								{
									loading === true 
									? <div className="loading-in-btn-wrapper"><LoadingSpinner /></div>
									: 'Subscribe via Email'
								}
							</button>
							<div className="fieldText_note ">By subscribing you agree to the <a href='https://odown.io/terms'>Odown Terms of Service</a> and acknowledge <a href='https://odown.io/privacy' >Odown's Privacy Policy</a>.</div>
							<div className="response-group"></div>
						</div>
					</form>
				</div>
			</div>
		</React.Fragment>
	)
	 
}
/**
 * SMS Channel Form
 * @returns 
 */
function SMSChannelForm(props){

	const [phoneNumber, setPhoneNumber] = useState('');
	const [loading, setLoading] = useState(false);
	const [phoneNumberRegex, setPhoneNumberRegex] = useState(/^[0-9]+$/)
	const [phoneNumberValid, setPhoneNumberValid] = useState(false);
	const [phoneNumberStartChange, setPhoneNumberStartChange] = useState(false)
	const [phoneNumberErrorResponse, setPhoneNumberErrorResponse] = useState('')
	const [phonePrefixe, setPhonePrefixe] = useState('us')
	const [phonePrefixeList, setPhonePrefixeList] = useState({})
	useEffect( () => {
		const phonePrefixeList = getPhonePrefixes();
		setPhonePrefixeList(phonePrefixeList)
	})
	 			

	/**
	 * SUBMIT THE SMS ALERT CHANNEL FOR SUBSCRIBERS 
	 */
	const submitSMSSubscription = async (event) => {
		
		event.preventDefault();

		setLoading(true)

		if(
			(typeof phoneNumber ==='undefined' || phoneNumber ===null || phoneNumber ==='' ) || 
			phoneNumberValid === false 
		){

			props.setResponse("An error occurred while subscribing via sms notification" , 'danger')
			setLoading(false)
			return ;
		}

		
		const headers = {
			'Content-Type': 'application/json',
		}

		let customConfig = {
			type : 'SMS',
			config :  {
				name : '',
				phoneNumber : `+${phonePrefixeList[phonePrefixe].num}${phoneNumber.substring(0,1)==='0' ? phoneNumber.substring(1) : phoneNumber}`
			}
		}

		 
		 
		await axios
			.post(`${process.env.REACT_APP_API_URL}public/statuspages/${props.statusPageDomain}/subscribers`, 
			customConfig
			,{
				headers: headers,
				 
			})
			.then(res => res.data ) 
			.then(data => { 

				setPhoneNumber('') // re initialize the phone number value 

				props.setResponse("Your subscription has been created successfully" , "success");
				setLoading(false); // stop loading ... 
				 
				 

					
			})
			.catch(err => {
				// if(typeof err.message==='undefined' || err.message ===null || err.message!=="API_HAS_BEEN_CANCELED"){
				
				// 	if(err.response && err.response.data.error !=='Unauthorized'){
						
				// 		props.setResponse(err.response.data.message , 'danger')
				// 	}else{
				// 		props.setResponse("An error occurred while subscribing via sms notification" , 'danger')

				// 	}
				// 	setLoading(false)
				// }
				if (err.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					// console.log(err.response.data);
					// console.log(err.response.status);
					// console.log(err.response.headers);
					if(err.response.status === 403){
						props.setResponse(err.response.data.message , 'danger')
					}else{
						props.setResponse("An error occurred while subscribing via sms notification" , 'danger')
					}

				} else if (err.request) {
					// The request was made but no response was received
					// `err.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					// console.log(err.request);
					props.setResponse("An error occurred while subscribing via sms notification" , 'danger')
				} else {
					// Something happened in setting up the request that triggered an err
					// console.log('err', err.message);
					props.setResponse("An error occurred while subscribing via sms notification" , 'danger')
				}

				setLoading(false)
			});

	}
	
	/**
	 * 
	 * @param {*} event 
	 */
	const handleChange_phoneNumberInput = (event) => {
		 
		const value = event.target.value ;

		let isValid = false;
		let isEmpty = true; 

		if (typeof value !== 'undefined' &&
			value !== "") {
			
			isEmpty = false;
			if (value.match(phoneNumberRegex) !== null) isValid = true;
		}

		setPhoneNumber(value);
		setPhoneNumberStartChange(true);
		setPhoneNumberValid(isValid);
		setPhoneNumberErrorResponse(isEmpty === true ? "The phone number field is required." : (isValid === false ?  "The phone number field does not have the right format." : "" ))
		
	 
	}

	const handleChange_phonePrefixeInput = (event) => {
		const value = event.target.value 

		setPhonePrefixe(value);
	}
	 
	return(
		<React.Fragment>
			<div className="channel-form-wrapper sms-channel-form" >
				<div className="channel-form-title">Get text message notifications whenever Statuspage <b>creates</b> or <b>resolves</b> an incident.</div>
				<div className="cr-form-wrapper">
					<form onSubmit={(e) => submitSMSSubscription(e)} >
						<div className="field-group">
							<label className="fluid">Phone number </label>
							<select className={`textField fluid margin-bottom-10`} value={phonePrefixe} onChange={handleChange_phonePrefixeInput} >
								{
									Object.keys(phonePrefixeList).map((item, index) => {
										return (
											<option key={index} value={item} >{phonePrefixeList[item].name}</option>
										)
									} )
								}
							</select>
							<input type="text" className={`textField fluid ${phoneNumberStartChange === true && phoneNumberErrorResponse !== "" ? 'error' : ''}`} value={phoneNumber} placeholder={`ex. 9876543210`} onChange={handleChange_phoneNumberInput} />
							<span className={`fieldText_alert--error ${phoneNumberStartChange === true && phoneNumberErrorResponse !== "" ? '' : 'hide'}`}>{phoneNumberErrorResponse}</span>
												
						</div>
						<div className="submit-fields-group">
							<button type="submit" className="btn btn-primary fluid margin-left-10" disabled={!phoneNumberValid}   >
								{
									loading === true 
									? <div className="loading-in-btn-wrapper"><LoadingSpinner /></div>
									: 'Subscribe via Text Message'
								}
							</button>
							<div className="fieldText_note ">By subscribing you agree to the <a href='https://odown.io/terms'>Odown Terms of Service</a> and acknowledge <a href='https://odown.io/privacy' >Odown's Privacy Policy</a>.</div>
							<div className="response-group"></div>
						</div>
					</form>
				</div>
			</div>
		</React.Fragment>
	)
	 
}
/**
 * WEBHOOK Channel Form
 * @returns 
 */
function WEBHOOKChannelForm(props){

	const [webhook, setWebhook] = useState('');
	const [loading, setLoading] = useState(false);
	const [webhookRegex, setWebhookRegex] = useState(/^http/)
	const [webhookValid, setWebhookValid] = useState(false);
	const [webhookStartChange, setWebhookStartChange] = useState(false)
	const [webhookErrorResponse, setWebhookErrorResponse] = useState('')
 
	useEffect( () => {
		 
	})
	 			

	/**
	 * SUBMIT THE SMS ALERT CHANNEL FOR SUBSCRIBERS 
	 */
	const submitWebhookSubscription = async (event) => {
		
		event.preventDefault();

		setLoading(true)
	 

		if(
			(typeof webhook ==='undefined' || webhook ===null || webhook ==='' ) || 
			webhookValid === false 
		){

			props.setResponse("An error occurred while subscribing via webhook URL" , 'danger')
			setLoading(false)
			return ;
		}

		
		const headers = {
			'Content-Type': 'application/json',
		}

		let customConfig = {
			type : 'WEBHOOK',
			config :  {
				name : '',
				url : webhook,
			}
		}

		 
		 
		await axios
			.post(`${process.env.REACT_APP_API_URL}public/statuspages/${props.statusPageDomain}/subscribers`, 
			customConfig
			,{
				headers: headers,
				 
			})
			.then(res => res.data ) 
			.then(data => { 

				setWebhook('') // re initialize the phone number value 

				props.setResponse("Your subscription has been created successfully" , "success");
				setLoading(false); // stop loading ... 
				 
				 

					
			})
			.catch(err => {
				// if(typeof err.message==='undefined' || err.message ===null || err.message!=="API_HAS_BEEN_CANCELED"){
				// 	if(err.response && err.response.data.error !=='Unauthorized'){

				// 		props.setResponse(err.response.data.message , 'danger')
				// 	}else{
				// 		props.setResponse("An error occurred while subscribing via webhook URL" , 'danger')

				// 	}
				// 	setLoading(false)
				// }
				if (err.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					// console.log(err.response.data);
					// console.log(err.response.status);
					// console.log(err.response.headers);
					if(err.response.status === 403){
						props.setResponse(err.response.data.message , 'danger')
					}else{
						props.setResponse("An error occurred while subscribing via webhook URL" , 'danger')
					}

				} else if (err.request) {
					// The request was made but no response was received
					// `err.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					// console.log(err.request);
					props.setResponse("An error occurred while subscribing via webhook URL" , 'danger')
				} else {
					// Something happened in setting up the request that triggered an err
					// console.log('err', err.message);
					props.setResponse("An error occurred while subscribing via webhook URL" , 'danger')
				}

				setLoading(false)
			});

	}
	
	/**
	 * 
	 * @param {*} event 
	 */
	const handleChange_webhookInput = (event) => {
		 
		const value = event.target.value ;

		let isValid = false;
		let isEmpty = true; 

		if (typeof value !== 'undefined' &&
			value !== "") {
			
			isEmpty = false;
			if (value.match(webhookRegex) !== null) isValid = true;
		}

		setWebhook(value);
		setWebhookStartChange(true);
		setWebhookValid(isValid);
		setWebhookErrorResponse(isEmpty === true ? "The webhook URL field is required." : (isValid === false ?  "The webhook URL field does not have the right format." : "" ))
		
	 
	}

	 
	 
	return(
		<React.Fragment>
			<div className="channel-form-wrapper sms-channel-form" >
				<div className="channel-form-title">Get webhook notifications whenever Statuspage creates, updates or resolves an incident.</div>
				<div className="cr-form-wrapper">
					<form onSubmit={(e) => submitWebhookSubscription(e)} >
						<div className="field-group">
							<label className="fluid">Webhook URL </label>
							<input type="text" className={`textField fluid ${webhookStartChange === true && webhookErrorResponse !== "" ? 'error' : ''}`} value={webhook} placeholder={`http://example.com/endpoint`} onChange={handleChange_webhookInput} />
							<span className={`fieldText_alert--error ${webhookStartChange === true && webhookErrorResponse !== "" ? '' : 'hide'}`}>{webhookErrorResponse}</span>
												
						</div>
						<div className="submit-fields-group">
							<button type="submit" className="btn btn-primary fluid margin-left-10" disabled={!webhookValid}   >
								{
									loading === true 
									? <div className="loading-in-btn-wrapper"><LoadingSpinner /></div>
									: 'Subscribe '
								}
							</button>
							<div className="fieldText_note ">By subscribing you agree to the <a href='https://odown.io/terms'>Odown Terms of Service</a> and acknowledge <a href='https://odown.io/privacy' >Odown's Privacy Policy</a>.</div>
							<div className="response-group"></div>
						</div>
					</form>
				</div>
			</div>
		</React.Fragment>
	)
	 
}

/**
 * 
 * @returns 
 */
function SlackChannelForm(props){

	const [installURL, setInstallURL] = useState(null)


	useEffect(() => {

	 	 
		// EXample : 
		// https://slack.com/oauth/v2/authorize?client_id=897172527474.1593345226673&scope=incoming-webhook,chat:write,channels:join&user_scope=&user_scope=&redirect_uri=https://subscriptions.hyperping.io/BCDF379D
		let redirectURL = process.env.REACT_APP_SLACK_AUTH_REDIRECTION;// `https://d9e7-89-29-162-216.ngrok.io/v1/auth/slack`;
		let installURL = `https://slack.com/oauth/v2/authorize?client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&scope=incoming-webhook,chat:write,channels:join&user_scope=&redirect_uri=${redirectURL}&state=${btoa(props.statusPageDomain)}`;

		setInstallURL(installURL)

	 
		
	} )

	return(
		<React.Fragment>
			<div className="channel-form-wrapper slack-channel-form" >
				<div className="channel-form-title">Get incident updates and maintenance status messages in Slack.</div>
				<div className="cr-form-wrapper">
					<a 
						href={installURL}
						className="btn btn-primary" >Subscribe via Slack</a>
					<div className="fieldText_note ">By subscribing you agree to the <a href='https://odown.io/terms'>Odown Terms of Service</a> and acknowledge <a href='https://odown.io/privacy' >Odown's Privacy Policy</a>.</div> 
				</div>
			</div>
		</React.Fragment>
	)
 
}
/**
 * 
 * @returns 
 */
function DiscordChannelForm(props){

	const [installURL, setInstallURL] = useState(null)


	useEffect(() => {

	 	 
		// EXample : 
		// https://slack.com/oauth/v2/authorize?client_id=897172527474.1593345226673&scope=incoming-webhook,chat:write,channels:join&user_scope=&user_scope=&redirect_uri=https://subscriptions.hyperping.io/BCDF379D
		// let installURL = `https://slack.com/oauth/v2/authorize?client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&scope=incoming-webhook,chat:write,channels:join&user_scope=&redirect_uri=${redirectURL}&state=${btoa(props.statusPageDomain)}`;
		const client_id = process.env.REACT_APP_DISCORD_CLIENT_ID;
		const redirect_uri = process.env.REACT_APP_DISCORD_AUTH_REDIRECTION
	
		let installURL = `https://discord.com/api/oauth2/authorize?`;
		installURL += `client_id=${String(client_id)}`
		installURL += `&redirect_uri=${encodeURIComponent(redirect_uri)}`
		installURL += `&response_type=code&scope=webhook.incoming`
		installURL += `&state=${btoa(props.statusPageDomain)}`

		setInstallURL(installURL)

	 
		
	} )

	return(
		<React.Fragment>
			<div className="channel-form-wrapper discord-channel-form" >
				<div className="channel-form-title">Get incident updates and maintenance status messages in Discord.</div>
				<div className="cr-form-wrapper">
					<a 
						href={installURL}
						className="btn btn-primary" >Subscribe via Discord</a>
					<div className="fieldText_note ">By subscribing you agree to the <a href='https://odown.io/terms'>Odown Terms of Service</a> and acknowledge <a href='https://odown.io/privacy' >Odown's Privacy Policy</a>.</div>
				</div>
			</div>
		</React.Fragment>
	)
 
}
/**
 * 
 * @returns 
 */
function RSSChannelForm(props){

	 
	 
 

	return(
		<React.Fragment>
			<div className="channel-form-wrapper rss-channel-form" >
				<div className="channel-form-title">Get the <Link to={`/history.atom`} >Atom Feed</Link> or <Link to={`/history.rss`}>RSS Feed</Link>.</div>
			</div>
		</React.Fragment>
	)
 
}

export default SubscribersDropDown;
 